import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Risks of using Platypus`}</h1>
    <p>{`Swapping and providing liquidity on Platypus come with certain risks. You are advised to research and understand the risks involved.`}</p>
    <a href="https://cdn.platypus.finance/Platypus_AMM_Yellow_Paper.pdf" target="_blank">Yellowpaper</a>
    <p>{`We also advise you to study our `}<a href="https://docs.platypus.finance/platypus-finance-docs/" target="_blank">{`operation manual`}</a>{` to familiarize yourself with Platypus; our StableSwap and the mechanism of single-asset liquidity provision.`}</p>
    <ol>
      <li parentName="ol">{`Swapping Tokens`}</li>
      <li parentName="ol">{`Depositing into Platypus Pool`}</li>
      <li parentName="ol">{`Withdrawing Tokens`}</li>
    </ol>
    <h2>{`Audits`}</h2>
    <p>{`Platypus is Audited by Hacken and Omniscia.`}</p>
    <p>{`However, security audits don't eliminate risks completely. Please don't contribute more than you can afford to lose to Platypus, especially as a liquidity provider. `}</p>
    <p>{`Using Platypus as an exchange user should be significantly less risky, but this is not advice.`}</p>
    <h2>{`Permanent Loss`}</h2>
    <p>{`If one of the stablecoins in the pool goes significantly down below the peg of 1.0 and never returns to the peg, liquidity providers may suffer from impairment loss. `}</p>
    <p>{`Further details please refer to Yellow Paper Section 4.3.`}</p>
    <h2>{`Risk Management`}</h2>
    <p>{`Platypus adopts following risk management policies to protect liquidity providers:`}</p>
    <ol>
      <li parentName="ol">{`We use price oracle (Chainlink) to check the price between the tokens upon swap, and the trade will be reverted if the price deviation between them is larger than 2%.`}</li>
      <li parentName="ol">{`In the case that the deposited token is significantly under-covered, we allow liquidity providers to withdraw in other assets of which the pool is over-covered.`}</li>
    </ol>
    <p>{`For further details please refer to Yellow Paper Section 8.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      