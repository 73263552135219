/** @jsx jsx */
import React, { useRef, useEffect, useState, useCallback, useLayoutEffect } from 'react'
import { jsx } from "theme-ui"
import Layout from "@lekoarts/gatsby-theme-cara/src/components/layout"
import RiskContent from "../sections/risk-content"
import { COLORS_HEX, CONTENT_CONTAINER_WIDTH, FONT_SIZES } from '../constants/constant'
import AppContext from '../context/AppContext'
import useWindowDimensions from '../utils/use-window-dimensions'
import Footer from '../components/footer'

const ArticleReading = ({path}) => {
  
  console.log('>>>>>> ?? ArticleReading path ?? <<<<<< : ', path)

  const { items, resetMenuItems, reviseMenuItem, setRecalculationOnResize } = React.useContext(AppContext);
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();

  useEffect(() => {
    // document.querySelector('#gatsby-focus-wrapper main > div').addEventListener('scroll', handleScroll);
    // console.log('███O███')
    // console.log('██OOO██')
    // console.log('█OO█OO█')
    // console.log('██OOO██')
    // console.log('███O███')
    // console.log('CHECK')
    resetMenuItems();
    setRecalculationOnResize(false);
    // reviseMenuItem({ viewability: '111' },1);
  }, []);
  
  useEffect(()=>{
    console.log('^&^&^&^ Menu items changed !!');
    console.log(items);
  },[items])
  return (
    <Layout>
      <div sx={{
        width: CONTENT_CONTAINER_WIDTH.map((unit, index)=>index < 1?`calc(100% - 3.25rem)`:unit),
        marginX: `auto`,
        paddingTop: `7rem`,
        paddingBottom: `5.5rem`,
        h1: {
          fontSize: [
            `${ windowWidth < 360 ? `8.9vw` : `232.51%`}`,
            null,
            `3.445rem`
          ]
        },
        h4: {
          // fontSize: [`1.444rem`, null, `1.875rem`, null, `2.0625rem`],
          fontSize: FONT_SIZES.subtitle,
          fontWeight: 500,
        },
        'p, li, a': {
          fontSize: FONT_SIZES.p,
        },
        'p, li': {
          color: `rgba(255, 255, 255, 0.7)`,
          '& > b': {
            color: `rgba(255, 255, 255, 1)`,
          }
        },
        a: {
          color: COLORS_HEX.primary
        },
        'p, ol, al, a': {
          '& + h2': {
            marginTop: `1.8rem`
          }
        }
      }} className='article-reading'>
        <RiskContent />
      </div>
      <Footer menuItems={items}/>
    </Layout>
  )
}

export default ArticleReading